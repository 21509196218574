<template>
  <v-card>
    <v-card-title
      class="headline primary dark white--text"
      primary-title
    >
      Batch No. {{batchDetails.batch_number}} details
      <v-spacer/>
      <!-- <v-btn icon dark @click="printJE()" v-if="batchDetails.document_type == 'DM'">
        <v-icon>mdi-printer-eye</v-icon>
      </v-btn> -->
      <v-btn icon dark @click="closeDialog(batchDetails.batch_number)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-if="batchDetails">
      <br>
	<v-layout>
		<v-flex v-if="getRemarks(documentDetails)" xs12 sm12 md12>
			<v-alert type="error" outlined>
				Approver Remarks - {{documentDetails[0].approved_remarks}}
			</v-alert>
		</v-flex>
	</v-layout>
      <v-layout>
        <v-flex xs12 sm12 md4>
         <h2>Journal Entry Information:</h2>
         <br>
         <!-- <h3>Date: {{documentDetails[0].document_date}}</h3> -->
         <h3>Batch No: {{batchDetails.batch_number}}</h3>
         <h3>Document Type: {{getDocuType(batchDetails.document_type)}}</h3>
         <h3>Currency: {{getCurrency(batchDetails.currency_id)}}</h3>
		<h3 v-if="parseFloat(batchDetails.dollar_rate) > 0">Rate: {{batchDetails.dollar_rate}}</h3>
		<h3 v-if="po_details.length !== 0">PO Number: {{ po_details[0].po_number }}</h3>
         <br>
        </v-flex>
        <v-spacer/>
        <v-flex xs12 sm12 md6>
          <h3>Attached Files:</h3>
          <v-layout column style="height: 100px">
            <v-flex style="overflow: auto" class="elevation-3">
              <v-data-table
                :headers="attachHeaders"
                :items="trxdisbDetailed"
                :loading="false"
                class="elevation-1"
                :hide-default-footer="true"
                :hide-default-header="true"
                style="margin:0"
				:items-per-page="1000"
              >
                <template v-slot:items="props">
                  <td>{{ props.item.title }}</td>					
                </template>
                <template v-slot:[`item.file`]="{ item }">
                  <v-icon class="text-right mr-2" :color="item.seen ? 'gray' : 'primary'" medium @click="viewItem(item.path)"
                    >mdi-eye-circle</v-icon
                  >
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
		<br>
		<div v-if="po_details.length !== 0">
			<h3>PO Attached Files:</h3>
			<v-layout column style="height: 100px">
				<v-flex style="overflow: auto" class="elevation-3">
					<v-data-table
						:headers="attachHeaders"
						:items="JSON.parse(po_details[0].upload_file)"
						:loading="false"
						class="elevation-1"
						:hide-default-footer="true"
						:hide-default-header="true"
						style="margin:0"
					>
						<template v-slot:[`item.file`]="{ item }">
						<v-icon class="text-right mr-2" :color="item.seen ? 'gray' : 'primary'" medium @click="viewItem(item.path)"
							>mdi-eye-circle</v-icon
						>
						</template>
					</v-data-table>
				</v-flex>
			</v-layout>
		</div>
          <div class="container" v-if="batchDetails.status == 6">
						<div class="large-12 medium-12 small-12 cell">
							<label>
								<p>Releasing Files:</p>
							</label>
							<label>
								<input type="file" id="files" ref="files" multiple v-on:change="handleFileUploads()"/>
							</label>
						</div>
					</div>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex xs12 sm12 md4>
          
        </v-flex>
      </v-layout>
      <h2>Accounting Entries</h2>
      <v-container class="doc-items-container" grid-list-md>
        <p class="caption"></p>
        <v-row justify="center" v-if="documentDetails.length > 0">
          <v-container outlined>
            <v-card outlined
              v-for="(doc_detail) in documentDetails" :key="doc_detail.key"
            >
              <template>
                <v-card-title dark class="document-card-title grey lighten-2">
                  Document No. {{doc_detail.document_number}}
                </v-card-title>
              </template>
              <v-container>
                <v-container grid-list-md>
				<v-layout>
                    <v-flex xs12 sm6 md12 v-if="doc_detail.regular_trx_id != 0">
						<v-alert type="error" outlined>
							{{doc_detail.regtrx_indicator === 2 ? "New Created Regular Transaction" : "Existing Regular Transaction"}}
						</v-alert>
						<!-- <h3 style="color: red">{{doc_detail.regtrx_indicator === 1 ? "New Created Regular Transaction" : "Existing Regular Transaction"}}</h3> -->
					</v-flex>
				</v-layout>
                  <v-layout wrap>
                    <v-flex xs12 sm6 md6>
                      <h4>Document Number: {{doc_detail.document_number}}</h4>
                      <h4>Payee: {{ getValue(payees, "id", doc_detail.payee_id, "payee_desc") }}</h4>
                      <h4>Document Date: {{ doc_detail.document_date }}</h4>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <h4>Transaction Type:  {{ getTransType(doc_detail.disbursement_type) }}</h4>
                      <h4>ATC Code: {{ getValue(dataAtc, "atc_code", doc_detail.atc_code, "atc_desc") }}</h4>
                      <h4>ATC Rate: {{ getValue(dataAtc, "atc_code", doc_detail.atc_code, "atc_rate") }}</h4>
                    </v-flex>
                    
                    <v-flex>
                      <h4>Remarks: {{doc_detail.remarks}}</h4>
                    </v-flex>
                  </v-layout>
                </v-container>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Analysis</th>
                        <th>Department</th>
                        <th>Branch</th>
                        <th>Section</th>
                        <th>Chart of Accounts</th>
                        <th>Debit</th>
                        <th>Credit</th>
                      </tr>
                    </thead>
					<tbody>
                      <tr
                        v-for="(detail, detail_key) in getDetails(doc_detail, 'details')"
                        :key="detail.detail_key"
                      >
                        <td>{{ detail_key + 1 }}</td>
                        <td>
                          {{ getValue(payees, "id", detail.payee_id, "payee_desc") }}
                        </td>
                        <td>
                          {{ getValue(dataDepts, "id", detail.department_id, "dept_code") }}
                        </td>
                        <td>
                          {{ getValue(branches, "id", detail.branch_id, "brnc_code") }}
                        </td>
                        <td>
                          {{ getValue(dataSections, "id", detail.section_id, "section_code") }}
                        </td>
                        <td>
                          {{ getValue(coaData, "acct_code", detail.account_code, "acct_desc") }}
                        </td>
                        <td class="text-right">
                          {{formatAmount(detail.debit_amount)}}
                        </td>
                        <td class="text-right">
                          {{formatAmount(detail.credit_amount)}}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <b>Total</b>
                        </td>
                        <td>&nbsp;</td>
                        <td class="text-right">
                        </td>
                        <td class="text-right">
                          <b>{{formatAmount(doc_detail.overallDebit)}}</b>
                        </td>
                        <td class="text-right">
                          <b>{{formatAmount(doc_detail.overallCredit)}}</b>
                        </td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-container>
            </v-card>
          </v-container>
        </v-row>
      </v-container>
      <br/>
      <v-container>
        <v-layout>
          <v-flex md6>
            <h4>Prepared By: {{preparer}}</h4>
          </v-flex>
          <v-flex md6 v-if="batchDetails.approved_by">
            <h4>Approved By: {{ batchDetails.approved_by }}</h4>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>

    <v-divider></v-divider>
    <!-- <v-card-actions v-if="documentDetails[0].status!==7 && currUser.max_amount !== null">
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        text
        @click="approveJournalEntry = true"
      >
      {{getLabel(batchDetails.status)}}
      </v-btn>
      <v-btn
        color="red"
        text
        @click="rejectJournalEntry = true"
      >
      Reject
      </v-btn>
    </v-card-actions> -->
	<v-card-actions v-if="batchDetails.status === 6 && this.preparer === this.currUser.user_last_name + ', ' + currUser.user_first_name">
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        text
        @click="approveDisbursement = true"
      >
      {{getLabel(batchDetails.status)}}
      </v-btn>
      <v-btn
        color="red"
        text
        @click="rejectDisbursement = true"
      >
      Reject
      </v-btn>
    </v-card-actions>
	<v-card-actions v-if="getApprover1()"> <!--&& parseFloat(currUser.max_amount) < parseFloat(level1.max_amount) + 1-->
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        text
        @click="approveJournalEntry = true"
      >
      {{getLabel(batchDetails.status)}}
      </v-btn>
      <v-btn
        color="red"
        text
        @click="rejectJournalEntry = true"
      >
      Reject
      </v-btn>
    </v-card-actions>
	<v-card-actions v-if="documentDetails[0].status===4 && parseFloat(currUser.max_amount) > parseFloat(level2.min_amount)"><!-- && parseFloat(currUser.max_amount) < parseFloat(level2.max_amount) + 1-->
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        text
        @click="approveJournalEntry = true"
      >
      {{getLabel(batchDetails.status)}}
      </v-btn>
      <v-btn
        color="red"
        text
        @click="rejectJournalEntry = true"
      >
      Reject
      </v-btn>
    </v-card-actions>
	<v-card-actions v-if="documentDetails[0].status===5 && parseFloat(currUser.max_amount) > parseFloat(level2.max_amount)">
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        text
        @click="approveJournalEntry = true"
      >
      {{getLabel(batchDetails.status)}}
      </v-btn>
      <v-btn
        color="red"
        text
        @click="rejectJournalEntry = true"
      >
      Reject
      </v-btn>
    </v-card-actions>
    <div class="text-center">
      <v-dialog
        v-model="approveJournalEntry"
        persistent max-width="600"
      >
        <v-card>
          <v-card-title
            class="headline primary white--text"
            primary-title
          >
            {{getLabel(batchDetails.status)}} Journal Entry
          </v-card-title>
          <br>
          <v-card-text>
            Are you sure you want to {{getLabel(batchDetails.status)}} this Journal Entry from Batch No. {{batchDetails.batch_number}}?
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="approve(batchDetails.batch_number, documentDetails[0].status)"
            >
              {{getLabel(batchDetails.status)}}
            </v-btn>
            <v-btn
              color="red"
              text
              @click="approveJournalEntry = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-dialog
        v-model="rejectJournalEntry"
        persistent max-width="600"
      >
        <v-card>
          <v-card-title
            class="headline primary white--text"
            primary-title
          >
            Reject Journal Entry
          </v-card-title>
          <br>
          <v-card-text>
            Are you sure you want to reject this Journal Entry from Batch No. {{batchDetails.batch_number}}?
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="rejectJournalEntryReason = true"
            >
              Reject
            </v-btn>
            <v-btn
              color="red"
              text
              @click="rejectJournalEntry = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-dialog
        v-model="rejectJournalEntryReason"
        persistent max-width="600"
      >
        <v-card>
          <v-card-title
            class="headline primary white--text"
            primary-title
          >
            Reason for Rejection
          </v-card-title>
          <br>
          <v-card-text>
            Please state the reason:
            <v-text-field v-model="rejectRemarks" label="Remarks" required></v-text-field>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="reject(batchDetails.batch_number, documentDetails[0].status)"
            >
              Reject
            </v-btn>
            <v-btn
              color="red"
              text
              @click="rejectJournalEntryReason = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-snackbar
      v-model="snackbar"
      right
    >
      {{ error_message }}
      <v-btn
        color="pink"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral';
import jsonToFormData from 'json-form-data';
import { getMonth, getYear, parseISO } from 'date-fns';
export default {
	props: {
		batchDetails: Array,
		documentDetails: Array
	},
	data() {
		return {
			level1: {},
			level2: {},
			level3: {},
			upload_files: '',
			upload_file: [],
			image_title: [],
			delete_file: [],
			seenAttachedFile:[],
			dataBranch: [],
			dataAtc: [],
			dataDepts: [],
			dataSections: [],
			dataUsers: [],
			dataDollarRate: [],
			nextPageDollarRate: '',
			dataRegularTrx: [],
			nextPageRegularTrx: '',
			nextPageUsers: '',
			nextPageSections: '',
			nextPageBranch: '',
			nextPageAtc: '',
			nextPageDepts: '',
			attachHeaders: [
				{
					text: 'File Name',
					align: 'left',
					sortable: false,
					value: 'title'
				},
				{ 
					text: 'Actions',
					align: 'right',
					sortable: false,
					value: 'file',
				},
			],
			panel: 0,
			detailHeaders:[
				{ text: 'Analysis', value: 'payee_id' },
				{ text: 'Department', value: 'department_id' },
				{ text: 'Branch', value: 'branch_id' },
				{ text: 'Section', value: 'section_id' },
				{ text: 'Chart of Accounts', value: 'account_code' },
				{ text: 'Debit', value: 'debit_amount' },
				{ text: 'Credit', value: 'credit_amount' },
			],
			error_message: 'Please view attached file before approving.',//'+this.getLabel2()+'
			snackbar: false,
			approveJournalEntry: false,
			rejectJournalEntry: false,
			rejectJournalEntryReason: false,
			rejectRemarks: '',
			partner_bank_files: [],
			batch_number_rules: [
				v => !!v || 'Batch Number is required',
			],
			date_rules: [
				v => !!v || 'Date is required',
			],
			payee_rules: [
				v => !!v || 'Payee is required',
			],
			bank_rules: [
				v => !!v || 'Bank is required',
			]
		};
	},
	computed: {
		...mapGetters({
			depts: 'dept/depts',
			branches: 'branch/branches',
			sections: 'section/sections',
			payees: 'payefile/payefiles',
			alphataxcodes: 'alphataxcode/alphataxcodes',
			headers: 'trxdisb/headers',
			currUser: 'auth/currUser',
			approvingofficers: 'approvingofficer/approvingofficers',
			viewDialog: 'trxdisb/viewDialog',
			regulartrx: 'regularTxns/regulartxns',
			preparer: 'trxdisb/preparer',
			trxdisbDetailed: 'trxdisb/trxdisbDetailed',
			dollarrates: 'dollarrate/dollarrates',
			currencies: 'currency/currencies',
			approvinglevel: 'approvinglevel/approvinglevels',
			coaData: 'coa/coaData',
			po_details: 'trxdisb/po_details',
			users: 'user/users',
		})
	},
	watch: {
		approvinglevel: {
			handler(val) {
				val.forEach(level => {
					if (level.level_code == 1){
						this.level1 = {'min_amount': parseFloat(level.min_amount),'max_amount': parseFloat(level.max_amount)};
					} else if (level.level_code == 2){
						this.level2 = {'min_amount': parseFloat(level.min_amount),'max_amount': parseFloat(level.max_amount)};
					} else if (level.level_code == 3){
						this.level3 = {'min_amount': parseFloat(level.min_amount),'max_amount': parseFloat(level.max_amount)};
					}
				});
			}
		},
		batchDetails: {
			handler(val) {
				this.$emit('update:batchDetails', val);
			},
			deep: true
		},
		documentDetails: {
			handler(val) {
				this.$emit('update:documentDetails', val);
			},
			deep: true
		},
		depts: {
			handler() {
				this.getDataDepts();
			}
		},
		alphataxcodes: {
			handler() {
				this.getDataAlphataxcodes();
			}
		},
		sections: {
			handler() {
				this.getDataSections();
			}
		},
		users: {
			handler() {
				this.getDataUsers();
			}
		},
		dollarrates: {
			handler() {
				this.getDataDollarRate();
			}
		},
		regulartrx: {
			handler() {
				this.getDataRegularTrx();
			}
		}
	},
	mounted() {
		this.$store.dispatch('approvinglevel/getApprovingLevels');
		this.$store.dispatch('dept/getDepts');
		this.$store.dispatch('branch/getBranches');
		this.$store.dispatch('section/getSections');
		this.$store.dispatch('payefile/getPayefiles');
		this.$store.dispatch('alphataxcode/getAlphataxcodes');
		this.$store.dispatch('regularTxns/getregularTxns');
		this.$store.dispatch('approvingofficer/getApprovingOfficers');
		this.$store.dispatch('dollarrate/getDollarrates');
		this.$store.dispatch('currency/getCurrencies');
		this.$store.dispatch('user/getUsers');
	},
	methods: {
		getApprover1(){
			let ableApprove = false;
			let user = this.dataUsers.filter(e => e.user_last_name + ', ' + e.user_first_name === this.preparer);
			
			if(user.length > 0){
				let approver = user[0].approver;
				if(this.documentDetails.length > 0){
					if(this.documentDetails[0].status===3 && parseFloat(this.currUser.max_amount) > parseFloat(this.level1.min_amount)){
						if(parseInt(approver) !== 0 && parseFloat(this.currUser.max_amount) === parseFloat(this.level1.max_amount) && parseInt(approver) !== parseInt(this.currUser.id)){
							ableApprove = false;
						} else {
							ableApprove = true;
						}
					}
				}
			}
			return ableApprove;
		},
		approve(batch_number, current_status) {
			let attachIsClicked = true;
			let attachment = [];
			let detail = [];
			this.error_message = '';
			let rate = 1;

			this.trxdisbDetailed.forEach(i => {
				attachment.push(i.path);
			});

			attachment.map(i => {
				let detailData = {document_number: i, matched: this.seenAttachedFile.includes(i)};
				detail.push(detailData);
			});

			detail.forEach(details => {
				if(!details.matched){
					attachIsClicked = false;
				}
			});

			if(attachIsClicked){
				let data = {};

				data.batch_number = batch_number;
				data.value = {};

				let filterByBatchNumber = this.documentDetails.filter(header => {
					return header.batch_number === batch_number;
				});

				let filterByRegTrx = this.documentDetails.filter(header => {
					return header.regular_trx_id > 0 && header.regular_trx_id != process.env.VUE_APP_REGULAR_TRX_ID_PLACEHOLDER ;
				});
				
				let filterByRegTrxType = this.documentDetails.filter(header => {
					return header.regular_trx_type !== 2 ;
				});

				rate = this.getRate();

				const totalAmount = filterByBatchNumber.reduce((sum, header) => {
					return sum + (parseFloat(header.amount) * parseFloat(rate));
				}, 0);
				
        
				data.value._method = 'PUT';
				if(this.upload_files.length != 0){
					data.value.image_title = this.image_title;
					data.value.upload_file = this.upload_file;
					for( var i = 0; i < this.upload_files.length; i++ ){
						let file = this.upload_files[i];
						data.value.image_title.push(file.name);
						data.value.upload_file.push(file);
					}
					data.value.delete_file = this.delete_file;
					for( var j = 0; j < this.trxdisbDetailed.length; j++ ){
						data.value.delete_file.push(this.trxdisbDetailed[j].filename);
					}
				}
        
				var options = {
					initialFormData: new FormData(),
					showLeafArrayIndexes: true,
					includeNullValues: false,
					mapping: function(value) {
						if (typeof value === 'boolean') {
							return +value ? '1': '0';
						}
						return value;
					}
				};
        
				var convertedFormData = '';

				let is_attachment_uploaded = data.value.upload_file;
				let current_user_name = this.currUser.user_last_name + ', ' + this.currUser.user_first_name;

				let level1 = 0;
				let level2 = 0;
				let level3 = 0;

				this.approvinglevel.forEach(level => {
					if (level.level_code == 1){
						level1 = parseFloat(level.max_amount);
					} else if (level.level_code == 2){
						level2 = parseFloat(level.max_amount);
					} else if (level.level_code == 3){
						level3 = parseFloat(level.min_amount);
					}
				});

				if (current_status === 2) {
					data.value.status = 7;
					data.value.headers = [];
					data.value.processing = 0;
					data.value.approved_remarks = '';
					data.value.reversal_indicator = this.documentDetails[0].reversal_indicator;
					convertedFormData = jsonToFormData(data.value, options);
					this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
				} else {
					switch(current_status) {
					case 3:
						this.approveProps(current_user_name, totalAmount, level1, current_status, 4, options, data, filterByRegTrx, filterByRegTrxType);
						break;
					case 4:
						this.approveProps(current_user_name, totalAmount, level2, current_status, 5, options, data, filterByRegTrx, filterByRegTrxType);
						break;
					case 5:
						this.approveProps(current_user_name, totalAmount, level3, current_status, 7, options, data, filterByRegTrx, filterByRegTrxType);
						break;
					case 8:
						data.value.status = 7;
						data.value.processing = 0;
						data.value.approved_remarks = '';
						data.value.approved_by = this.currUser.id;
						data.value.document_type = this.batchDetails.document_type;
						data.value.reversal_indicator = this.documentDetails[0].reversal_indicator;
						data.value.headers = [];
						convertedFormData = jsonToFormData(data.value, options);
						this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
						break;
					default:
						data.value.status = 7;
						data.value.processing = 0;
						data.value.approved_remarks = '';
						data.value.document_type = this.batchDetails.document_type;
						data.value.reversal_indicator = this.documentDetails[0].reversal_indicator;
						convertedFormData = jsonToFormData(data.value, options);
						this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
					}
				}

				if (current_status === 6) {
					if (is_attachment_uploaded !== undefined) {
						this.$store.dispatch('trxdisb/setViewDialog', false);
					}
				} else {
					if (this.preparer !== current_user_name) {  
						this.$store.dispatch('trxdisb/setViewDialog', false);
					}
				}

			} else {
				this.error_message = 'Please view attached file before approving.';//'+this.getLabel2()+'
				this.approveJournalEntry = false;
				this.snackbar = true;
			}
		},
		approveProps(current_user_name, totalAmount, amountThreshold, current_status, moveToStatus, options, data, filterByRegTrx, filterByRegTrxType) {
			var convertedFormData = '';
			let regularTrx = {};

			if (this.preparer == current_user_name) {
				this.error_message = 'Cannot be approved by the same Preparer and Approver';
				this.approveJournalEntry = false;
				this.snackbar = true;
			} else {
				//If current status is 5, the status will automatically proceed as released
				if (totalAmount > amountThreshold && filterByRegTrx.length < 1 && current_status !== 5 && filterByRegTrxType.length < 1) {
					//Go to Approver 2 or 3
					data.value.status = moveToStatus;
					data.value.headers = [];
					data.value.processing = 0;
					data.value.approved_remarks = '';
					data.value.approved_by = this.currUser.id;
					data.value.document_type = this.batchDetails.document_type;
					convertedFormData = jsonToFormData(data.value, options);
					this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
				} else {
					//Go to Releasing
					this.documentDetails.filter(header => {
						this.dataRegularTrx.filter(trxdata => {			
							if (trxdata.doc_number === header.document_number) {
								regularTrx = {
									id: trxdata.id,
									doc_number: trxdata.doc_number,
									is_approved: 1
								};

								data.regular_trx_id = trxdata.id;
								this.$store.dispatch('regularTxns/updateregularTxns', regularTrx);
							}
						});
					});
					data.value.status = 7;
					data.value.headers = [];
					data.value.processing = 0;
					data.value.approved_remarks = '';
					data.value.reversal_indicator = this.documentDetails[0].reversal_indicator;
					data.value.approved_by = this.currUser.id;
					data.value.document_type = this.batchDetails.document_type;
					
					convertedFormData = jsonToFormData(data.value, options);
					this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
				}	
			}
		},
		reject(batch_number, current_status) {
			let attachIsClicked = true;
			let attachment = [];
			let detail = [];
			this.error_message = '';

			let current_user_name = this.currUser.user_last_name + ', ' + this.currUser.user_first_name;

			this.trxdisbDetailed.forEach(i => {
				attachment.push(i.path);
			});

			attachment.map(i => {
				let detailData = {document_number: i, matched: this.seenAttachedFile.includes(i)};
				detail.push(detailData);
			});

			detail.forEach(details => {
				if(!details.matched){
					attachIsClicked = false;
				}
			});

			if(attachIsClicked){

				let data = {};
				data.batch_number = batch_number;
				data.value = {
					status: 1,
					processing: 0,
					approved_remarks: this.rejectRemarks,
					document_type: this.batchDetails.document_type,
					_method: 'PUT'
				};
			
				var options = {
					initialFormData: new FormData(),
					showLeafArrayIndexes: true,
					includeNullValues: false,
					mapping: function(value) {
						if (typeof value === 'boolean') {
							return +value ? '1': '0';
						}
						return value;
					}
				};

				let convertedFormData = jsonToFormData(data.value, options);

				if (current_status === 6) { 
					this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
					this.$store.dispatch('trxdisb/setViewDialog', false);
				} else {
					if (this.preparer == current_user_name) {
						this.error_message = 'Cannot be rejected by the same Preparer and Approver';
						this.rejectJournalEntry = false;
						this.rejectJournalEntryReason = false;
						this.snackbar = true;
					} else if (this.rejectRemarks === '') { 
						this.error_message = 'Reason for Rejection is required';
						this.rejectJournalEntry = false;
						this.rejectJournalEntryReason = false;
						this.snackbar = true;
					} else {
						this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
						this.$store.dispatch('trxdisb/setViewDialog', false);
					}
				}
			} else {
				this.error_message = 'Please view attached file before rejecting.';
				this.rejectJournalEntry = false;
				this.rejectJournalEntryReason = false;
				this.snackbar = true;
			}
		},
		closeDialog(batch_number) {
			this.documentDetails.forEach((header) => {
				if (header.batch_number === batch_number) {
					let data = {};
										
					data.batch_number = header.batch_number;
					data.value = {
						processing: 0,
						status: header.status,
						document_type: header.document_type,
						_method: 'PUT'
					};
          
					var options = {
						initialFormData: new FormData(),
						showLeafArrayIndexes: true,
						includeNullValues: false,
						mapping: function(value) {
							if (typeof value === 'boolean') {
								return +value ? '1': '0';
							}
							return value;
						}
					};
					let convertedFormData = jsonToFormData(data.value, options);
					this.$store.dispatch('trxdisb/updateTrxdisbStatus', {batch_number: data.batch_number, value: convertedFormData});
				}
			});
			this.$store.dispatch('trxdisb/setViewDialog', false);
		},
		formatAmount(amount){
			return numeral(amount).format('0,0.00');
		},
		getValue(tableData, columnId, id, column){
			for (var i=0; i < tableData.length; i++) {
				if (tableData[i][columnId] === id) {
					if (columnId === 'acct_code'){
						return tableData[i][columnId] + ' - ' + tableData[i][column];
					} else {
						return tableData[i][column];
					}
				}
			}
		},
		getDocuType(type){
			switch(type){
			case 'JE':
				return 'Journal Entry';
			}
		},
		getTransType(type){
			switch(type){
			case 1:
				return 'Regular';
			case 2:
				return 'Non-Regular';
			}
		},
		getLabel(status){
			if(status == 6){
				return 'Release';
			} else {
				return 'Approve';
			}
		},
		printJE() {
			let item = this.documentDetails[0];
			let routeData = this.$router.resolve({path: '/preview-trx/'+btoa(JSON.stringify(item)), data: item});
			window.open(routeData.href, '_blank'); 
		},
		handleFileUploads(){
			this.upload_files = this.$refs.files.files;
		},
		viewItem(item) {
			const details = this.trxdisbDetailed.filter(detail => {
				if(detail.path === item){
					detail.seen = true;
				}
				return detail;
			});
			this.$store.commit('trxdisb/TRXDISBS_DETAIL', details);

			//let url = process.env.VUE_APP_STORAGE_URL + '/' + this.billingDetails[0].billing_invoice_no + '/' + item;
			let path = atob(item);
			fetch(path)
				.then(res => res.blob()) // Gets the response and returns it as a blob
				.then(blob => {
					const objectURL = URL.createObjectURL(blob);
					window.open(objectURL, '_blank');
				});
			// window.open(path, '_blank');
			this.seenAttachedFile.push(item);
		},
		getRate(){
			let rate = 1;
			let dollar_rate = this.documentDetails[0].dollar_rate;
			let curr_code = 'P';
			let currentRate = [];
			let previousRate = [];
			let currency = [];
			let dateFormat = this.documentDetails[0].document_date;
			let currentMonth = getMonth(parseISO(dateFormat)) + 1;
			let currentYear = getYear(parseISO(dateFormat));

			if (dollar_rate !== '0.0000'){
				rate = dollar_rate;
			} else {
				if(this.documentDetails[0].currency_id !== 1){
					currency = this.currencies.filter(det => {
						return det.id == this.documentDetails[0].currency_id;
					});

					if(currency.length > 0 ){
						curr_code = currency[0].cur_code;
					}

					currentRate = this.dataDollarRate.filter(det => {
						return det.period_no == currentMonth && det.fiscal_year == currentYear && det.cur_code == curr_code;
					});

					if(currentRate.length > 0){
						rate = parseFloat(currentRate[0].dolr_rate);
					} else {
						previousRate = this.dataDollarRate.filter(det => {
							return det.period_no == currentMonth && det.fiscal_year == currentYear && det.cur_code == curr_code;
						});

						if(previousRate.length > 0){
							rate = parseFloat(previousRate[0].dolr_rate);
						}
					}
				}
			}

			return rate;
		},
		async getDataDollarRate() {
			this.dataDollarRate = this.dollarrates.data;
			this.nextPageDollarRate= this.dollarrates.next_page_url;
			let page = 2;

			while (this.nextPageDollarRate !== null){
				await this.$store.dispatch('alphataxcode/getData', page).then(response => {
					this.nextPageDollarRate = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataDollarRate.push(details);
					});
				});
				page++;
			}
		},
		async getDataAlphataxcodes(){
			this.dataAtc = this.alphataxcodes.data;
			this.nextPageAtc= this.alphataxcodes.next_page_url;
			let page = 2;

			while (this.nextPageAtc !== null){
				await this.$store.dispatch('alphataxcode/getData', page).then(response => {
					this.nextPageAtc = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataAtc.push(details);
					});
				});
				page++;
			}
		},
		async getDataDepts(){
			this.dataDepts = this.depts.data;
			this.nextPageDepts= this.depts.next_page_url;
			let page = 2;

			while (this.nextPageDepts !== null){
				await this.$store.dispatch('dept/getData', page).then(response => {
					this.nextPageDepts = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataDepts.push(details);
					});
				});
				page++;
			}
		},
		async getDataSections(){
			this.dataSections = this.sections.data;
			this.nextPageSections= this.sections.next_page_url;
			let page = 2;

			while (this.nextPageSections !== null){
				await this.$store.dispatch('section/getData', page).then(response => {
					this.nextPageSections = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataSections.push(details);
					});
				});
				page++;
			}
		},
		async getDataUsers(){
			this.dataUsers = this.users.data;
			this.nextPageUsers = this.users.next_page_url;
			let page = 2;

			while (this.nextPageUsers !== null){
				await this.$store.dispatch('users/getData', page).then(response => {
					this.nextPageUsers = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataUsers.push(details);
					});
				});
				page++;
			}
		},
		async getDataRegularTrx(){
			this.dataRegularTrx = this.regulartrx.data;
			this.nextPageRegularTrx= this.regulartrx.next_page_url;
			let page = 2;

			while (this.nextPageRegularTrx !== null){
				await this.$store.dispatch('regularTxns/getData', page).then(response => {
					this.nextPageRegularTrx = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataRegularTrx.push(details);
					});
				});
				page++;
			}
		},
		getCurrency(id){
			let curr_code = '';
			const currency = this.currencies.filter(det => det.id === id);

			if(currency.length > 0 ){
				curr_code = currency[0].cur_desc;
			}

			return curr_code;
		},
		getDetails (data, type){
			let details = [];
			details = data[type].filter(det => det.batch_number === data.batch_number);
			return details;
		},
		getRemarks(data){
			let remarks = false;
			if(data.length > 0) {
				remarks = data[0].approved_remarks ? true : false;
			}
			return remarks;
		}
	}
};
</script>
