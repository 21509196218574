<template>
	<v-container class="doc-items-container" grid-list-md>
		<p class="caption"></p>
		<v-row justify="center" v-if="documentDetails.length > 0">
			<v-expansion-panels focusable multiple tile outlined>
				<v-expansion-panel outlined expand
					v-for="(doc_detail, key) in documentData" :key="doc_detail.key"
				>
					<v-expansion-panel-header>
						<template>
							<v-card-title dark class="document-card-title">
								Document No. {{doc_detail.document_number}}
								<v-spacer></v-spacer>
								<v-btn icon @click.stop="removeheader(key, doc_detail.document_number)" v-if="headers.length > 1">
									<v-icon>mdi-close</v-icon>
								</v-btn>
							</v-card-title>
						</template>
						
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-container grid-list-md>
							<v-layout wrap>
								<v-flex xs12 sm12 md12>
									<p>Select Transaction Type:</p>
									<v-radio-group v-model="disbursement_type[key]" row>
										<v-radio label="Regular" :value="1"></v-radio>
										<v-radio label="Non-Regular" :value="2"></v-radio>
									</v-radio-group>
								</v-flex>
								
								

								<v-flex xs12 sm12 md12 v-if="disbursement_type[key] == '1' && doc_detail.regular_trx_id !== 10000000">
									<p>Regular Transaction:</p>
									<v-radio-group v-model="regular_trx_type[key]" row>
										<v-radio label="Select from the Existing List" :value="1"></v-radio>
										<v-radio label="Create a new one" :value="2"></v-radio>
									</v-radio-group>
								</v-flex>
								
								<v-flex xs12 sm6 md12 v-if="disbursement_type[key] == '1' && regular_trx_type[key] == '1' && regular_trx.length > 0">
									<p>Select an Existing Regular Transaction:</p>
									<!-- <v-radio-group row v-for="(trxdetail, key) in regular_trx" :key="trxdetail.key" v-model="doc_detail.regular_trx_selected" @change="populateRegTrxData(doc_detail.document_number, trxdetail)" readonly>
										<v-radio :label='trxdetail.txn_type' :value="trxdetail.id" :key="key"></v-radio>
									</v-radio-group>  -->
									<v-select
										:items="regular_trx"
										item-text="txn_type"
										item-value="id"
										readonly
										v-model="doc_detail.regular_trx_selected"
										@change="
											populateRegTrxData2(header.document_number, header.regular_trx_selected)
										"
									>
									</v-select>
								</v-flex>
								<v-flex xs12 sm6 md12 v-if="disbursement_type[key] == '1' && regular_trx_type[key] == '1' && regular_trx.length < 1">
									<p>No available regular transactions available. Please create a new one</p>
								</v-flex>
								<!-- <v-flex xs12 sm6 md4>
									<v-text-field label="Transaction Type" required></v-text-field>
									<v-autocomplete
										:items="transactions"
									
										hide-details
										item-text="trns_desc"
										item-value="trns_code"
										label="Transaction Type"
									></v-autocomplete>
								</v-flex> -->
								<v-flex xs12 sm12 md12>
									<v-text-field label="Title" v-if="disbursement_type[key] == 1" v-model="txn_type[key]" required></v-text-field>
								</v-flex>
								<v-flex xs12 sm6 md4>
									<v-text-field
										label="Document Number"
										v-model="doc_detail.document_number"
										required
									></v-text-field>
								</v-flex>
								<!-- <v-flex xs12 sm6 md4>
									<v-text-field label="Amount" required></v-text-field>
								</v-flex>-->
								<v-flex xs12 sm6 md4>
									<!-- <v-text-field label="Transaction Type" required></v-text-field> -->
									<v-autocomplete
										v-model="doc_detail.payee_id"
										:items="payees"
										v-on:focus="getPayees"
										item-text="payee_desc"
										item-value="id"
										ref="payeeDropdown"
										v-on:change="payees_on_change(doc_detail.payee_id, documentDetails[key], key)"
										label="Payee"
										return-object
									></v-autocomplete>
								</v-flex>

								<v-flex xs12 sm6 md4>
									<!-- <v-text-field label="Transaction Type" required></v-text-field> -->
									<v-autocomplete
										v-model="doc_detail.payee_account_id"
										:items="payee_banks"
										editable
										item-text="item_data"
										item-value="payee_account_id"
										label="Payee Bank"
									></v-autocomplete>
								</v-flex>
								<v-flex xs12 sm6 md4>
									<!-- <v-text-field label="Transaction Type" required></v-text-field> -->
									<v-autocomplete
										v-model="doc_detail.atc_code"
										:items="dataAtc"
										editable
										item-text="atc_desc"
										item-value="atc_code"
										label="ATC Code"
										
										@change="atc_code_on_change($event)"
									></v-autocomplete>
								</v-flex>
								<v-flex xs12 sm6 md2>
									<v-text-field label="ATC Rate" v-model="doc_detail.atc_rate" readonly required></v-text-field>
								</v-flex>
								<v-flex xs12 sm6 md2>
									<v-checkbox 
										label="Tax Base"
										false-value="0"
										true-value="1"
										v-model="enable_tax_base[key]"
										@change="checkTaxBase($event, key)"
									></v-checkbox>
								</v-flex>
								<v-flex xs12 sm6 md4>
									<v-text-field label="Tax Base Amount" 
										v-model="tax_base_amount[key]"
										:readonly="enable_tax_base[key] === '0'"
										@keyup="computeTaxBase($event, doc_detail.atc_rate, key)"
									></v-text-field>
								</v-flex>
								<v-flex xs12 sm6 md12>
									<h3>Computed Tax:</h3>
									<p> {{tax_computed ? tax_computed : 0}}</p>
								</v-flex>
								<v-flex xs12 sm6 md12>
									<v-textarea
										v-model="doc_detail.remarks"
										label="Remarks"
										rows="2"
										counter=2000
									></v-textarea>
								</v-flex>
								<!-- <v-flex xs12 sm12 md12 v-if="batchDetails.document_type == 'OB'">
									<v-checkbox 
										v-model="headers[key].auto_debit" 
										label="Is this an auto-debit transaction?"
										false-value="0"
										true-value="1"
									></v-checkbox>
								</v-flex> -->
							</v-layout>
						</v-container>
						<!-- <p><b>Date:</b> {{doc_detail.document_date}}</p>
						<p><b>Bank:</b> {{doc_detail.bank_account_id}}</p>
						<p><b>Payee ID:</b> {{doc_detail.payee_id}}</p>
						<p><b>Payee Account ID:</b> {{doc_detail.payee_account_id}}</p>
						<p><b>Remarks:</b> {{doc_detail.remarks}}</p> -->
						<trx-detail 
							ref="trxDetails" 
							:batchDetails="batchDetails" 
							:documentDetails="documentDetails" 
							:doc_detail="doc_detail" 
							:currency_id.sync="currency_id"
							:dataDepts.sync="dataDepts"
							:dataSections.sync="dataSections"
						></trx-detail>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<v-card-actions>
					<v-btn right @click="addHeader" color="primary" dark>
						<v-icon>mdi-plus</v-icon>
						<span>Add New Document</span>
					</v-btn>
				</v-card-actions>
			</v-expansion-panels>
		</v-row>
	</v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import trxDetail from './editJournalDetail';
import numeral from 'numeral';

export default {
	props: {
		batchDetails: Array,
		documentDetails: Array,
		dataAtc: Array,
		dataDepts: Array,
		dataSections: Array,
		currency_id: {
			type: Number
		},
	},
	components: {
		'trx-detail': trxDetail
	},
	data() {
		return {
			detailHeaders:[
				{ text: 'Analysis', value: 'payee_id' },
				{ text: 'Department', value: 'department_id' },
				{ text: 'Branch', value: 'branch_id' },
				{ text: 'Section', value: 'section_id' },
				{ text: 'Chart of Accounts', value: 'account_code' },
				{ text: 'Debit', value: 'debit_amount' },
				{ text: 'Credit', value: 'credit_amount' },
			],
			batch_datepicker: false,
			cheque_datepicker: [],
			due_datepicker: [],
			voucher_datepicker: [],
			documentData: [],
			date_format: '####-##-##',
			overallDebit: 0,
			overallCredit: 0,
			populate_entries: [],
			regular_trx: [],
			snackbar: false,
			isvalid: false,
			updateJournalEntry: false,
			cancelJournalEntry: false,
			image_title: [],
			upload_file: [],
			delete_file: [],
			payee_banks: [],
			disbursement_type: [],
			regular_trx_type: [],
			enable_tax_base: [],
			txn_type: [],
			taxCodesList: [],
			tax_base_amount: [],
			tax_computed: '',
			computed_tax_list: [],
			upload_files: '',
			file: '',
			minDate: '',
			maxDate: '',
			error_message: 'Error: Please fill out the required fields',
			batch_number_rules: [
				v => !!v || 'Batch Number is required',
			],
			date_rules: [
				v => !!v || 'Date is required',
			],
			payee_rules: [
				v => !!v || 'Payee is required',
			],
			bank_rules: [
				v => !!v || 'Bank is required',
			],
			attHeaders: [
				{
					text: 'File Name',
					align: 'left',
					sortable: false,
					value: 'filename'
				},
				{ 
					text: 'Actions',
					align: 'right',
					sortable: false,
					value: 'file',
				},
			]
		};
	},
	computed: {
		...mapGetters({
			// coas: 'coa/coaSubs',
			// depts: 'dept/depts',
			branches: 'branch/branches',
			// sections: 'section/sections,
			payeefile: 'payefile/payefiles',
			banks: 'bank/banks',
			bpbanks: 'bpbank/bpbanks',
			payeebanks: 'payeebank/payeebanks',
			// alphataxcodes: 'alphataxcode/alphataxcodes',
			// transactions: 'transaction/transactions',
			headers: 'trxdisb/headers',
			regulartrx: 'regularTxns/regulartxns',
			editDialog: 'trxdisb/editDialog',
			currUser: 'auth/currUser',
			trxdisbDetails: 'trxdisb/trxdisbDetails',
			trxdisbDetailed: 'trxdisb/trxdisbDetailed',
			currencies: 'currency/currencies',
		}),
		payees(){
			let data = this.payeefile.filter(e => e.status === 1);
			return data;
		}
	},
	watch: {
		documentDetails: {
			handler() {
			},
			deep: true
		},
		tax_base_amount: {
			handler() {
			},
			deep: true
		},
		tax_computed: {
			handler() {
			},
			deep: true
		},
		currency_id: {
			handler(val){
				this.$emit('update:currency_id', val);
			},
			deep: true
		},
	},
	created() {
		this.documentData = this.documentDetails;
		this.tax_base_amount.push('0');
		this.tax_computed = '0';
	},
	mounted() {
		this.$store.dispatch('currency/getCurrencies');
		// this.getAlphaTaxCodes();

		const that = this;

		this.documentDetails.forEach((header, index) => {
			if (header.regular_trx_id === 0) {
				that.disbursement_type[index] = 2;
			} else {
				that.disbursement_type[index] = 1;
			}

			


			let account_id = '';

			setTimeout(function(){
				that.payees.forEach(payee_details => {
					that.payee_banks = (!payee_details.payee_banks ? [] : payee_details.payee_banks);
					if(that.payee_banks.length !== 0 ){
						account_id = payee_details.payee_banks[0].payee_account_id;
					} else {
						account_id = '';
					}
				});
				that.atc_code_on_change(header.atc_code);
			}, 3000);
			
			setTimeout(function() {
				header.payee_account_id = account_id;
			}, 5000);
		});
	},
	methods: {
		// getCoas(){
		// 	this.$store.dispatch('coa/getCoaDropdown');
		// },
		// getSections() {
		// 	this.$store.dispatch('section/getSectionDropdown');
		// },
		getBranches() {
			this.$store.dispatch('branch/getBranchDropdown');
		},
		// getDepts() {
		// 	this.$store.dispatch('dept/getDeptDropdown');
		// },
		getPayees() {
			this.$store.dispatch('payefile/getPayefileDropdown'); 
		},
		getPayeeBanks() {
			this.$store.dispatch('payeebank/getPayeeBankDropdown');
		},
		getBpBanks() {
			this.$store.dispatch('bpbank/getBpbankDropdown');
		},
		// getAlphaTaxCodes() {
		// 	this.$store.dispatch('alphataxcode/getAlphataxcodeDropdown');
		// },
		computeTaxBase(event, atc_rate, key) {
			const that = this;
			let currentValue = 0;
			
			if (typeof event === 'number') {
				currentValue = event;
			} else {
				currentValue = event.target.value;
			}

			let computation = currentValue * parseFloat(atc_rate/100);
			this.tax_computed = numeral(computation).format('0,0.00');

			let countTBAEntry = this.documentData[key].details.filter(function(detail) {
				return detail.remarks === 'Tax Based Amount' && detail.document_number === that.documentData[key].document_number;
			});
			
			if (computation > 0) {
				if (countTBAEntry.length > 0) {
					this.documentData[key].details.forEach(detail => {
						if (detail.remarks === 'Tax Based Amount') { 
							detail.document_number = this.documentData[key].document_number;
							detail.document_date = format(new Date(), 'yyyy-MM-dd');
							detail.account_code = this.currency_id === 1 ? 2117001 : 2117002;
							detail.remarks = 'Tax Based Amount';
							detail.debit_amount = numeral(0).format('0,0.[00]');
							detail.credit_amount = numeral(computation).format('0,0.[00]');
						}
					});
				} else {
					let detailValue = {
						payee_id: this.documentData[key].details[0].payee_id,
						department_id: this.documentData[key].details[0].department_id,
						section_id: this.documentData[key].details[0].section_id,
						regionalhead_id: 1,
						account_code : this.currency_id === 1 ? 2117001 : 2117002,
						branch_id: this.documentData[key].details[0].branch_id,
						document_number: this.documentData[key].document_number,
						document_date: format(new Date(), 'yyyy-MM-dd'),
						remarks: 'Tax Based Amount',
						debit_amount: numeral(0).format('0,0.[00]'),
						credit_amount: numeral(computation).format('0,0.[00]'),
						branches: this.documentData[key].details[0].branches,
						sections: this.documentData[key].details[0].sections
					};

					this.documentData[key].details.unshift(detailValue);
				}
			} else {
				this.documentData[key].details.forEach(detail => {
					if (detail.remarks === 'Tax Based Amount') {
						this.documentData[key].details.splice(0, 1);
					}
				});
			}
			

			this.computed_tax_list[key] = this.tax_computed;
		},
		atc_code_on_change(atc_code_value) {
			const taxCodesList = this.dataAtc;
			const that = this;

			let filterAlphaTaxCode = taxCodesList.filter(taxCode => {
				return taxCode.atc_code === atc_code_value;
			});

			this.documentDetails.filter((header, index) => {
				if (header.atc_code === atc_code_value) {
					if(filterAlphaTaxCode.length > 0){
						header.atc_rate = filterAlphaTaxCode[0].atc_rate;
					}
				}

				let filterTaxBasedEntry = header.details.filter(detail => detail.remarks === 'Tax Based Amount');
				
				let computedTaxAmount = 0;
				let originalTaxBase = 0;

				//Computes the original tax base amount
				if (filterTaxBasedEntry.length > 0) {
					computedTaxAmount = parseFloat(filterTaxBasedEntry[0].credit_amount.replace(/,/g, ''));
					originalTaxBase = ((computedTaxAmount * filterAlphaTaxCode[0].atc_rate) / (header.atc_rate / 100)) / header.atc_rate;				
					that.tax_base_amount[index] = originalTaxBase;
					that.enable_tax_base[index] = '1';
					that.computeTaxBase(originalTaxBase, filterAlphaTaxCode[0].atc_rate, index);
				} else {
					that.tax_base_amount[index] = 0;
					that.enable_tax_base[index] = '0';
				}
			});
		},
		payees_on_change(value, headersKey){
			if (value) {
				//const data = this.$data;
				// let branch_id = '';
				// let section_id = '';
				// let department_id = '';
				
				this.payees.filter(payee_details => {
					if(payee_details.id == value.id) {
						// this.branches.filter(branches_details => {
						// 	if(branches_details.brnc_code == payee_details.brnc_code){
						// 		branch_id = branches_details.id;
						// 	}
						// });
						
						// this.sections.filter(section_details => {
						// 	if(section_details.section_code == payee_details.section_code){
						// 		section_id = section_details.id;
						// 	}
						// });

						// this.depts.filter(depts_details => {
						// 	if(depts_details.dept_code == payee_details.dept_code){
						// 		department_id = depts_details.id;
						// 	}
						// });

						this.payee_banks = payee_details.payee_banks;

						// if(payee_details.payee_banks.length !== 0 ){
						// 	headersKey.payee_account_id = payee_details.payee_banks[0].payee_account_id;
						// } else {
						// 	headersKey.payee_account_id = '';
						// }

						headersKey.atc_code = payee_details.atc_code;
						if(!payee_details.atc_code){
							headersKey.atc_rate = '';
						}

						this.dataAtc.filter(atc_details => {
							if(atc_details.atc_code === payee_details.atc_code){
								headersKey.atc_rate = atc_details.atc_rate;
							}
						});
					}
				});

				// const getCurrentDetails = this.documentDetails[index].details;

				// const filterDetails = getCurrentDetails.filter(header => header.document_number === headersKey.document_number);

				// console.log(filterDetails);

				// filterDetails.forEach((detailData) => {
				// 	if (detailData.remarks !== 'Tax Based Amount') {
				// 		//Predefined for now. Data from API should be normalized and must return ID values.
				// 		detailData.payee_id = value.id;
				// 		detailData.department_id = department_id;
				// 		detailData.section_id = section_id;
				// 		// detailData.regionalhead_id = 1;
				// 		detailData.branch_id = branch_id;
				// 		// detailData.account_code = 0;
				// 		detailData.remarks = 'New Entry';
				// 		// detailData.debit_amount = 0;
				// 		// detailData.credit_amount = 0;
				// 	} else {
				// 		//Predefined for now. Data from API should be normalized and must return ID values.
				// 		detailData.payee_id = value.id;
				// 		detailData.department_id = department_id;
				// 		detailData.section_id = section_id;
				// 		// detailData.regionalhead_id = 1;
				// 		detailData.branch_id = branch_id;
				// 	}

				// 	// this.getDeptData(department_id, index, headersKey.document_number);
				// });
			}
		},
		checkTaxBase(is_checked, key) {
			if (is_checked === '1') {
				this.enable_tax_base[key] = '1';
			} else {
				this.enable_tax_base[key] = '0';
			}
		},
		async addHeader() {
			//let randomID = Math.floor(Math.random() * 1000000);
			//For testing purposes only....
			let unique_id = '';
			let data = { 
				batch_number: this.batchDetails.batch_number,
				document_date: format(new Date(), 'yyyy-MM-dd'),
				document_type: this.batchDetails.document_type,
				edit_by: this.currUser.id
			};
			await this.$store.dispatch('trxdisb/getDocumentNumber', data).then(response => {
				unique_id = parseInt(response.data.data.document_number);
			});

			this.documentDetails.push({
				company_id: 1,
				batch_number: this.batchDetails.batch_number,
				regular_trx_type: '2',
				disbursement_type: '1',
				regular_trx_selected: '1',
				document_number: unique_id,
				payee_bank: [],
				atc_code: 'WC100',
				currency_id: 1,
				dollar_rate: '',
				vat: '12',
				amount: '20000',
				regular_trx_id: '0',
				bank_account_id: '',
				details: [{
					section_id: 0,
					regionalhead_id: 1,
					document_number: unique_id,
					document_date: format(new Date(), 'yyyy-MM-dd'),
					account_code: '',
					debit_amount: 0,
					credit_amount: 0,
				}]
			});
		},
		removeheader(key, document_number) {
			if (this.documentDetails.length > 1) {
				this.documentDetails.splice(key, 1);

				let filterDetailsByDocument = [];

				filterDetailsByDocument = this.documentDetails[0].details.filter(detail => detail.document_number !== document_number);
				this.documentDetails[0].details = filterDetailsByDocument;
				// this.validations.headers.splice(key, 1);
			}
		},
		checkMonth(date) {
			let formatDate = new Date(date);
			let getMonth = formatDate.getMonth() + 1;

			if (getMonth !== 12) {
				return true;
			} else {
				return false;
			}
		},
		populateRegTrxData2(document_number, data) {
			let reg_trx_Data = this.regular_trx.find(o => o.id === data);
			this.getPayees();
			// this.getAlphaTaxCodes();
			// this.getDepts();
			this.getBranches();
			// this.getSections();
			// this.getCoas();
			
			let get_acct_entries = JSON.parse(reg_trx_Data.acct_entries);

			let filter_existing_entries = this.populate_entries.filter(function(entry) {
				return entry.document_number !== document_number;
			});

			this.populate_entries = filter_existing_entries;

			var that = this;


			this.headers.forEach(function(header) {
				if (header.document_number === document_number) {
					header.payee_id = reg_trx_Data.payee_id;
					header.txn_type = reg_trx_Data.txn_type;
					header.atc_code = reg_trx_Data.atc_code;
					header.atc_rate = reg_trx_Data.atc_rate;
					header.currency_id = 1;
					header.dollar_rate = '';
					header.vat = '12';
					header.remarks = reg_trx_Data.remarks;
					header.regular_trx_id = reg_trx_Data.id;
					header.enable_tax_base = '0';

					get_acct_entries.forEach((entry) => {
						that.populate_entries.push({
							payee_id: entry.analysis,
							department_id: entry.department,
							section_id: entry.section,
							regionalhead_id: 1,
							branch_id: entry.branch,
							document_number: document_number,
							document_date: format(new Date(), 'yyyy-MM-dd'),
							account_code: entry.account_code,
							remarks: entry.remarks,
							debit_amount: entry.debit.replace(/,/g, ''),
							credit_amount: entry.credit.replace(/,/g, ''),
						});
					});

					that.details = that.populate_entries;

					that.details.forEach((detail, index) => {
						that.getDeptData(detail.department_id, index, document_number);
					});
				}
			});
		},
	}
};
</script>
<style scoped>

</style>
